import axios from 'axios'

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

$api.interceptors.request.use(
  (config: any) => {
    const access_token = localStorage.getItem('access_token')

    return {
      ...config,
      headers: { Authorization: `Bearer ${access_token}` },
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

$api.interceptors.response.use(
  function (response) {
    if (response.headers.token) {
      document.cookie = `token=${response.headers.token}`
    }

    return response
  },
  function (error) {
    // const message = error.response?.data?.message
    // alert(message || 'Непредвиденная ошибка')

    return Promise.reject(error)
  }
)

// TODO: раскомментишь, как реализуешь механизм refresh токена.
// $api.interceptors.response.use(
//     (config) => {
//         return config
//     },
//     async (error) => {
//         const originalRequest = error.config
//         if (
//             error.response?.status === 401 &&
//             error.config &&
//             !error.config._isRetry
//         ) {
//             originalRequest._isRetry = true
//             try {
//                 const response = await axios.get(`${process.env.API_URL}/auth/refresh`, {
//                     withCredentials: true,
//                 })
//                 localStorage.setItem("access_token", response.data.token)
//                 return $api.request(originalRequest)
//             } catch (e) {
//                 console.log("НЕ АВТОРИЗОВАН")
//             }
//         }
//         throw error
//     },
// )

export default $api

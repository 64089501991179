import {axios, Core} from 'core'
import {useAlertStore} from 'store'

export default function useLogin() {
    const alert = useAlertStore()

    async function login(data: { phone: string; password: string }) {
        alert.loading({title: 'Загрузка пользователя...'})

        await axios.post<LoginResponse>('/auth/login', data)
            .then(res => {
                localStorage.setItem("access_token", res.data.token)
                window.location.href = '/inventories';

                return res.data;
            })
            .catch(err => {
                console.log(err)

                alert.error({
                    title: err.response.data?.title ?? 'Ошибка',
                    subtitle: err.response.data?.message,
                    buttons: [{text: 'Ок', onClick: alert.clear}],
                })
            })
    }

    return {login}
}

type LoginResponse = {
    capabilities: Core.TPermission[]
    parks: Core.TPark[]
    role: string
    token: string
    user: Core.TUser
}

import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Header.module.scss'
import { Button, Icon } from 'ui'
import { useCookies } from 'react-cookie'
import User from './User'
import { useWindowDimensions } from 'hooks'
import { Core } from 'core'

export default function Header({ toggleSidebar }: HeaderProps) {
  const { width } = useWindowDimensions()
  const removeCookie = useCookies(['token', 'user'])[2]

  const showReportsLink =
    Core.Park.capabilities.report || Core.User.capabilities.salary

  function logout() {
    localStorage.removeItem("access_token")
    removeCookie('user')
    window.location.href = '/';
  }

  return (
    <div className={styles.container}>
      {width > 600 && (
        <Button
          className={styles.burger}
          style={{ background: 'none' }}
          onClick={toggleSidebar}
        >
          <div>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Button>
      )}
      <img src="/logo.png" alt="" className={styles.logo} />
      <User />
      {width > 600 ? (
        <React.Fragment>
          <Link to="/settings" className={styles.settings}>
            <Icon name="settings" />
          </Link>
          {showReportsLink && (
            <Link to="/reports" className={styles.reports}>
              <Icon name="excel" />
            </Link>
          )}
          {/* {canSendShedule && <SendShedule />} */}
          <Button
            component="button"
            text="Выйти"
            size="SMALL"
            theme="CANCEL"
            onClick={logout}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Button
            className={styles.burger}
            style={{ background: 'none' }}
            onClick={toggleSidebar}
          >
            <div>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Button>
        </React.Fragment>
      )}
    </div>
  )
}

type HeaderProps = {
  toggleSidebar: () => void
}
